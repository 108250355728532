body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
  background-image: url("/public/joblybackground.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.form-label {
  text-align: left;
  font-weight: 700;
  color: rgb(88, 88, 88)
}

input {
  height: 12px;
  padding: 6px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  filter: drop-shadow(0px 3px  3px rgba(45, 14, 108, 0.3));
}

form {
  width: 30%;
  display: flex;
  flex-direction: column;

  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius: 10px;
}