
.Loading {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 60%;
  filter: drop-shadow(0px 2px  2px rgba(11, 89, 71, 0.3));
  background-color: white;
  padding: 20px;
}

.Loading h2 {
  font-size: 1.2rem;
  margin: 0px;
}

.Loading p {
  margin-bottom: 0px;
}


