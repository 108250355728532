.CompanyCard-Link {
  display: inline-block;
  width: 60%;
  color: inherit;
}
.CompanyCard {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  width: 80%;
  filter: drop-shadow(0px 2px  2px rgba(11, 89, 71, 0.3));
  background-color: white;
  padding: 20px;
}

.CompanyCard h6 {
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  margin: 0px;
}

.CompanyCard img {
  height: 40px;
}

.CompanyCard p {
  margin-bottom: 0px;
}


