button {

  cursor: pointer;
  outline: 0;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: .25rem;
  margin: .5rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  :hover {
      color: #fff;
      background-color: #0b5ed7;
      border-color: #0a58ca;
  }
}

.Alert {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  border-radius: 10px;
  background-color: #f8d7da;
  color: #994148;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
}


.Alert h2 {
  color: #9e1722;
  font-weight: 700;
}

.Alert p {
  margin: 0px;
}

.Homepage {
  color: rgb(236, 234, 234);
  background-color:rgba(10, 88, 204, .3);
  width: 35%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 10px 20px;
}

.Homepage p {
  font-size: 1.1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.Homepage-bold {
  font-weight: bold;
  color: rgb(0, 255, 150);
}