.NavBar {
  height: 50px;
  background-color: rgb(0, 93, 176);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

a {
  text-decoration: none;
}

.NavBar a {
  color: rgb(242, 242, 242);
}

.NavBar a.active {
  color: rgb(0, 255, 150);
  font-weight: 700;

}